import { Component, HostListener } from '@angular/core';


@Component({
  selector: 'app-website-landing-page',
  templateUrl: './website-landing-page.component.html',
  styleUrls: ['./website-landing-page.component.scss']
})
export class WebsiteLandingPageComponent{
  headerClass = ''


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.headerClass = '';
    // Handle scroll event here
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    console.log('Scrolled', scrollPosition);
    if(scrollPosition > 200) {  
      this.headerClass = 'change-header-color'
    }
  }
  
}
