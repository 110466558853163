import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from '../app.component';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})


export class LandingPageComponent {
  title = 'chaviti-website';
  pattern=/06([0-9]{8})/;
  emailFormControl = new FormControl('', [Validators.email]);
  matcher = new MyErrorStateMatcher();
  userFormControl = new FormControl('' , [Validators.required]);
  phoneNumberFromControl =  new FormControl('' , [Validators.maxLength(10), Validators.minLength(10)])
  hasResponseSubmitted = false;
  headerClass = '';


  constructor(private contactService: ContactService, private router: Router) {}

  saveText() {
    this.hasResponseSubmitted = false;
    const emailValue = JSON.stringify(this.emailFormControl.value);
    const payload = {
      name: JSON.stringify(this.userFormControl.value),
      email:emailValue,
      phone_number: JSON.stringify(this.phoneNumberFromControl.value)
    }
    console.log('informaiton', payload)

    this.contactService.registerUser(payload).subscribe({
      next: (response: any) => {
        this.hasResponseSubmitted = true;
      },
      error: (err) => {
        this.hasResponseSubmitted = false;
      }})
  }

  loginUser() {
    const payload = {
      username: this.userFormControl.value,
      password:this.phoneNumberFromControl.value,
    }
    localStorage.setItem('user', String(this.userFormControl.value));

    this.contactService.validateUser(payload).subscribe({
      next: (response: any) => {
        console.log('resp')
        this.router.navigate(['/login']);
      },
      error: (err) => {
        localStorage.clear();
        this.hasResponseSubmitted = false;
      }})

  }
}
