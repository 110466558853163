// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuthenticated = this.isAuthenticated();
    if (isAuthenticated) {
      return true;
    } else {
      // Optionally, redirect to the login page
      this.router.navigate([''], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  isAuthenticated(): boolean {
    const username = localStorage.getItem('user')
    if (username === 'svcphonepe') {
      return true
    } else {
     return false;
    }
  }

}
