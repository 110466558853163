<div [class]="defineClass" class="chaviti-header-container">
    <mat-toolbar color="primary" class="transparent">
        <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
              <mat-icon>menu</mat-icon>
            </button>
            <div fxShow="true" fxHide.lt-md class="row-level-navigation-information">
                <span class="company-name">Chaviti</span>
                <ng-container *ngFor="let eachVal of headerContent">
                    <button mat-button routerLink="{{eachVal.route}}">{{eachVal.name}}</button>
                </ng-container>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- <mat-sidenav-container fxFlexFill class="chaviti-side-nav-header">
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
                <a href="#" mat-list-item>Menu Item 1</a>
                <a href="#" mat-list-item>Menu Item 2</a>
                <a href="#" mat-list-item>Menu Item 3</a>
                <a href="#" mat-list-item>Menu Item 4</a>
                <a href="#" mat-list-item>Menu Item 5</a>
                <a href="#" mat-list-item>Menu Item 6</a>
            </mat-nav-list>
        </mat-sidenav>
    </mat-sidenav-container> -->

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
                <a href="#" mat-list-item>Menu Item 1</a>
                <a href="#" mat-list-item>Menu Item 2</a>
                <a href="#" mat-list-item>Menu Item 3</a>
                <a href="#" mat-list-item>Menu Item 4</a>
                <a href="#" mat-list-item>Menu Item 5</a>
                <a href="#" mat-list-item>Menu Item 6</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>